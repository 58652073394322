import React from 'react';
import logo from './logo.svg';
import Background from './bg_wall.jpg';
import './App.css';

class Step1 extends React.Component {
  render() {
    if (this.props.currentStep !== 1) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
    <div className="form-group">
      <label htmlFor="code"> Enter the Code:</label><br/>
      <input
        className="form-control"
        id="code"
        name="code"
        type="text"
        placeholder="code"
        value={this.props.code}
        onChange={this.props.handleChange}
      />
    </div>
    )
  }
}

class Step2 extends React.Component {
  render() {
    if (this.props.currentStep !== 2) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
      <div>
      <p>Congratulations!  You have escaped from Cell Block 58!</p>
      </div>
    )
  }
}
      
class Step3 extends React.Component {
  render() {
    if (this.props.currentStep !== 3) { // Prop: The current step
      return null
    }
    // The markup for the Step 3 UI
    return(
      <div>
      <p>That is not the right code, you will stay locked up forever!</p>
      </div>
    )
  }
}

class MasterForm extends React.Component 
{
  constructor(props) {
    super(props);
    this.state = {
        currentStep: 1,
        code: '',
    };
    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const {name, value} = event.target
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { code } = this.state
    if (code === "2615") {
      this.setState({
        currentStep: 2
      });
    } 
    else 
    {
      this.setState({
        currentStep: 3,
      });
    }
  }

  render() {
  return (
    <React.Fragment>
    <div>
    <form onSubmit={this.handleSubmit}>
    <Step1 
      currentStep={this.state.currentStep} 
      handleChange={this.handleChange}
      code={this.state.code}
    />
    <Step2 
      currentStep={this.state.currentStep} 
      handleChange={this.handleChange}
    />
    <Step3 
      currentStep={this.state.currentStep} 
      handleChange={this.handleChange}
    /> 
    </form>
    </div>
    </React.Fragment>
    );
  }
}

var sectionStyle = {
  width: "100%",
  height: "400px",
  backgroundImage: `url(${Background}`
};


      
function App() {
  // <img src={logo} className="App-logo" alt="logo" />
  // <img src=`url(${Background}`/>
  // <img src={Background} />
        
     
  return (
    <div className="App">  
      <header className="App-header">
      <section style={ sectionStyle }>
      <h1>Escape from Cellblock 58</h1>
        <MasterForm />
      </section>
      </header>
    </div>
  );
}

export default App;
